import axios from "axios";
import authHeader from "./auth-header";
import setBaseURL from "./baseURL";

class AuthService {

  async register(login, senha) {
      
    const data = {"LOGIN": login,"SENHA": senha};

    const response = await axios.post(setBaseURL + "loginjwt", 
                                data,
                                {
                                  headers: {
                                              Accept: "application/json"
                                            },
                                  auth: {
                                    username: "Troltech", password: "@#troltech@#"
                                  },
                                  withCredentials: false

                                });
    return response;
  }

  logout() {
    localStorage.removeItem("token");
  }

  login(idusuario) {
    return axios.get(setBaseURL + "loginjwt/"+idusuario, {
        headers: authHeader()
    });
  }

  getCurrentToken() {
    return localStorage.getItem('token');
  }
}

export default new AuthService();
