import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import PraticaDownloads from './site/componentes/pratica/downloads/index.jsx';
import Login from './site/componentes/pratica/login/index.jsx';

/* Páginas */
import Site from './site/site.jsx';
//import Login from './app/login.jsx';

function App(){
    return (
      <Router>
        <Routes>
          <Route path='/' element={<Site />} />
          <Route path='/pratica/login' element={<Login />} />
          <Route path='/pratica/downloads' element={<PraticaDownloads />} />
        </Routes>
      </Router>
    );
  }

export default App;