import React from 'react';
import Menu from './componentes/menu';
import Banner from './componentes/banner';
import Features from './componentes/features';
//import Testemunho from './componentes/testemunho';
import Footer from './componentes/footer';

function Site(){
    return <div>
        <Menu/>
        <Banner/>
        <Features/>
{/*        <Testemunho/> */}
        <Footer/>
    </div>;
  }

export default Site;