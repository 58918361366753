import React from 'react';

function Banner(){
    return <section id="banner">
        <div className="container">

            <div className="row">
                <div className="col-lg-6 col-sm-12"> {/* lg = desktop; md = tablet; sm = mobile */}
                    <h1>Uma empresa de desenvolvimento de software sob medida para seu negócio.</h1>
                    <h4>Aumente o controle e a qualidade do atendimento da sua empresa com um software ou app de gestão.</h4>
                </div>
                <div id="carouselExampleSlidesOnly" className="carousel slide col-lg-6 col-sm-12" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img src="Images\screenshot-01.png" className="d-block w-100" alt="screenshot-01"/>
                        </div>
                        <div className="carousel-item">
                            <img src="Images\screenshot-02.png" className="d-block w-100" alt="screenshot-02"/>
                        </div>
{/*
                        <div className="carousel-item">
                            <img src="Images\screenshot-03.png" className="d-block w-100" alt="screenshot-03"/>
                        </div>
                        <div className="carousel-item">
                            <img src="Images\screenshot-04.png" className="d-block w-100" alt="screenshot-04"/>
                        </div>
*/}
                        <div className="carousel-item">
                            <img src="Images\screenshot-05.png" className="d-block w-100" alt="screenshot-05"/>
                        </div>
                        <div className="carousel-item">
                            <img src="Images\screenshot-06.png" className="d-block w-100" alt="screenshot-06"/>
                        </div>
                    </div>
                </div>
            </div>


            {/*
            <div className="row">
                <div className="col-lg-6 col-sm-12"> // lg = desktop; md = tablet; sm = mobile 
                    <h1>Uma empresa de desenvolvimento de software sob medida para seu negócio.</h1>
                    <h4>Aumente o controle e a qualidade do atendimento da sua empresa com um software ou app de gestão.</h4>
                    <button type="button" className="btn btn-dark btn-lg btn-banner">Criar uma conta</button>
                    <button type="button" className="btn btn-outline btn-lg btn-banner">Fazer login</button>
                </div>
                <div className="col-lg-6 col-sm-12">
                    <img src="images/screenshot-01.png" alt="crm" />
                </div>
            </div>
            */}
        </div>
    </section>;
  }

export default Banner;