import axios from "axios";

export const api = axios.create(
    {
        headers: {
            Accept: "application/json"
        },
        auth: {
            username: "Troltech", password: "@#troltech@#"
        },
        withCredentials: false
    }
);

export default api;
