import React from 'react';
import { Link } from 'react-router-dom';
import './index.css';

function PraticaDownloads() {
    return(
    <>
        <nav className="navbar fixed-top navbar-expand-md navbar-light">
            <div className="container">
                <a className="navbar-brand" href="#banner">
                    <img src="../Images/logo_cabecalho.png" alt="" height="40" />
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item">
                            <Link to="/" className="nav-link">Sair</Link>
                        </li>
                    </ul>
                </div>    
            </div>
        </nav>

        <br />
        <br />

        <div className="div-card w-75 mt-5">
            <div className="card">
                <div className="card-header">
                    <img src="../Images/pratica01.png" alt="" height="40" />
                    <div className="text-center">
                        <h4>
                            Prática Logística - Página de downloads
                        </h4>
                    </div>
                </div>
                <div className="card-body">
                    <p className="card-text">Bem vindo à página de downloads da Prática logística. Aqui você efetua o download das últimas versões dos instaladores dos softwares desenvolvidos pela Troltech Sistemas em parceria com a empresa de consultoria Prática logística.</p>
                </div>
            </div>
        </div>

        
        <div className="card text-center w-75 mb-5 mt-5">
            <div className="card-header">
                <img src="../Images/ForzaAdm.png" alt="" height="40" />
                Forza Adm
            </div>
            <div className="card-body">
                <h4 className="card-title">Data: 20/04/2022</h4>
                <h5 className="card-title">Versão do instaldor: 1.2</h5>
                <a href="/pratica/instaladores/forzaadm/forzaadm_1.2.rar" 
                   className="btn btn-dark btn-baixar">
                       Clique aqui para baixar
                </a>
            </div>
        </div>
        
        <div className="card text-center w-75 mt-5 mb-5">
            <div className="card-header">
                <img src="../Images/Forza.png" alt="" height="40" />
                Forza
            </div>
            <div className="card-body">
                <h4 className="card-title">Data: 29/06/2022</h4>
                <h5 className="card-title">Versão do instaldor: 1.9</h5>
                <a href="/pratica/instaladores/forza/forza_1.9.rar" 
                   className="btn btn-dark btn-baixar">
                       Clique aqui para baixar
                </a>
            </div>
        </div>
        
        <div className="card text-center w-75 mt-5 mb-5">
            <div className="card-header">
                <img src="../Images/ForzaServer.png" alt="" height="40" />
                Forza Server
            </div>
            <div className="card-body">
                <h4 className="card-title">Data: 29/06/2022</h4>
                <h5 className="card-title">Versão do instaldor: 1.10</h5>
                <a href="/pratica/instaladores/forzaserver/forzaserver_1.10.rar" 
                   className="btn btn-dark btn-baixar">
                       Clique aqui para baixar
                </a>
            </div>
        </div>
        
        <div className="card text-center w-75 mt-5 mb-5">
            <div className="card-header">
                <img src="../Images/ForzaAlerta.png" alt="" height="40" />
                Forza Alerta
            </div>
            <div className="card-body">
                <h4 className="card-title card-alerta">x64 (versão 64 bits)</h4>
                <h5 className="card-title">Data: 30/06/2022</h5>
                <h6 className="card-title">Versão do app: 1.0.0.6</h6>
                <p>Versão 64bits, para aparelhos com android entre as versões 7 e 12 e que suportam aplicatios de 64bits.</p>
                <a href="/pratica/instaladores/forzaalerta/dow/android/x64/1.0.0.6/ForzaAlerta.apk" 
                   className="btn btn-dark btn-baixar">
                       Clique aqui para baixar
                </a>
            </div>
            <div className="card-body">
                <h4 className="card-title card-alerta">x86 (versão 32 bits)</h4>
                <h5 className="card-title">Data: 30/06/2022</h5>
                <h6 className="card-title">Versão do app: 1.0.0.6</h6>
                <p>Versão 32bits, para aparelhos com android entre as versões 7 e 12. Esta versão destina-se a aparelhos mais básicos(versão de entrada), que não são compatíveis com aplicatios 64bits.</p>
                <a href="/pratica/instaladores/forzaalerta/dow/android/x86/1.0.0.6/ForzaAlerta.apk" 
                   className="btn btn-dark btn-baixar">
                       Clique aqui para baixar
                </a>
            </div>
        </div>

        <p className="mt-5 text-muted text-center">&copy; Desenvolvido por TrolTech Sistemas</p>

    </>
    );
}

export default PraticaDownloads;