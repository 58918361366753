import React, { useState } from 'react';
import AuthService from '../../../../services/auth-service';
import { Navigate, Link } from 'react-router-dom';
import api from '../../../../services/api';
import setBaseURL from "../../../../services/baseURL";
import './index.css';

function Login() {

    const [login, setLogin] = useState(0);
    const [senha, setSenha] = useState('');

    //const [idUsuario, setIDUsuario] = useState(0);
    //const [token, setToken] = useState('');

    const [avancar, setAvancar] = useState('');
    const [mensagem, setMensagem] = useState('');

    function Logar() {
        
        if (!login){
            setMensagem('Digite seu login!');
            setAvancar('N');
            return;
        }

        if (!senha){
            setMensagem('Digite sua senha!');
            setAvancar('N');
            return;
        }
        
        AuthService.register(login, senha)
        .then(response => {

            if (response.data.RESULT === 'OK') {
                //setToken(response.data.TOKEN);
                //setIDUsuario(response.data.ID_USUARIO);
                
                // Se login permitido, vai gravar no histórico de logins
                const dados = {"ID_USUARIO": response.data.ID_USUARIO, "MODULO": 'S'};

                api.post(setBaseURL + 'historicologins',
                         dados
                ).then(response => {
                    setAvancar('S');
                })
                .catch(error => {
                    setAvancar('N');
                    setMensagem("Não foi possível registrar o login, tente novamente mais tarde, caso o erro persista entre em contato com o suporte da TrolTech Sistemas.");
                });

            }
            else {
                setAvancar('N');
                setMensagem("Erro ao logar, verifique os dados digitados.");
            }
        }
        ).catch(error => {
            setAvancar('N');
            setMensagem("Erro ao logar, verifique os dados digitados.");
        });
    }

    return(
        <>

            <nav className="navbar fixed-top navbar-expand-md navbar-light">
                <div className="container">
                    <a className="navbar-brand" href="#banner">
                        <img src="../Images/logo_cabecalho.png" alt="" height="40" />
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto">
                            <li className="nav-item">
                                <Link to="/" className="nav-link">Sair</Link>
                            </li>
                        </ul>
                    </div>    
                </div>
            </nav>;

            <div className="d-flex align-items-center text-center form-container mt-5">
                <form className="form-signin">
                    <h1 className="h3 mb-3 fw-normal">Login</h1>
                    <div className="form-floating">
                        <input onChange={(e) => setLogin(e.target.value)} type="text" className="form-control" id="login" placeholder="login" maxLength="40"/>
                        <label htmlFor="login">Entre com seu login</label>
                    </div>
                    <div className="form-floating">
                        <input onChange={(e) => setSenha(e.target.value)}  type="password" className="form-control" id="Senha" placeholder="Senha" maxLength="11"/>
                        <label htmlFor="Senha">Entre com sua senha</label>
                    </div>
                    <button onClick={Logar}  className="w-100 btn btn-lg btn-primary mb-5" type="button">Avançar</button>
                    {
                        avancar === 'N' ?
                        <div className="alert alert-danger mt-2" role="alert">{mensagem}</div> : null
                    }
                    {
                        avancar === 'S' ?
                        <Navigate to={"/pratica/downloads" } /> : null // + login + "/" + senha} /> : null
                    }
                    <p className="mt-5 text-muted">&copy; Desenvolvido por TrolTech Sistemas</p>
                </form>
            </div>
        </>
    )
}

export default Login;